const refs = {
  body: document.querySelector('body'),
  header: document.querySelector('header'),
  loader: document.querySelector('.loader'),
  modalForm: document.querySelector('.modal-form'),
  modalFormBtnClose: document.querySelector('.modal-form__btn--close'),
  btnUp: document.querySelector('.btn-up'),
  btnChat: document.querySelector('.btn-chat'),
  chatList: document.querySelector('.chat__list'),

  sectionHero: document.getElementById('hero'),
  sectionServices: document.getElementById('services'),

  servicesTabNavList: document.querySelector('.services__tabs-nav .tabs-nav__list'),
  servicesTabContentList: document.querySelector('.services__tabs-content .tabs-content__list'),
  servicesTabElements: document.querySelectorAll('[data-service]'),
};
export default refs;