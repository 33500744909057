import { refs, hideElement, showElement } from "/src/common/js";

const openBtn = document.querySelector('.modal-form__btn--open');
const backdrop = refs.modalForm.querySelector('.backdrop');

openBtn.addEventListener('click', openModal);
document.addEventListener('click', chekBtnAndOpenModal);

function chekBtnAndOpenModal(e) {
  if (!e.target.classList.contains('modal-form__btn--open')) return;
  openModal();
}

function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}

function onEscKeyPress(e) {
  if (backdrop.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}

export function openModal() {
  showElement(refs.modalForm);
  refs.body.classList.add('modalIsOpen');

  backdrop.addEventListener('click', onBackdropClick);
  window.addEventListener('keydown', onEscKeyPress);
  refs.modalFormBtnClose.addEventListener('click', closeModal);
}

export function closeModal() {
  hideElement(refs.modalForm);
  refs.body.classList.remove("modalIsOpen");

  backdrop.removeEventListener('click', onBackdropClick);
  window.removeEventListener('keydown', onEscKeyPress);
  refs.modalFormBtnClose.removeEventListener('click', closeModal);
}